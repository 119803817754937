import React from 'react'
import CollectionBanner from '../Components/CollectionPages/CollectionBanner'
import CollectionPage1 from '../Components/CollectionPages/CollectionPage1'

const CollectionScreenOne = () => {
    return (
        <div className="w-100 d-flex flex-column flex-wrap">
            <CollectionBanner />
            <CollectionPage1 />
        </div>
    )
}

export default CollectionScreenOne