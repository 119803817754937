// @ts-nocheck
import React, { useState } from "react";
import { bg1 } from "../assets/img";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../Components/RegisterAndLoginComp/ForgotPassword";
import ChangePassword from "../Components/RegisterAndLoginComp/ChangePassword";

const ForgetPasswordScreen = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const onCLickSuccessHandler = () => {
    setShowSuccess(true);
  };
  return (
    <div className="d-flex flex-column flex-md-row sign-up position-relative align-items-center justify-content-center">
      <h5
        // onClick={() => navigate("/")}
        className="sect-title mb-0 primary log-logo">
        Ninos
      </h5>
      <img src={bg1} alt="" className="bg1" />
      {!showSuccess ? (
        <ForgotPassword onCLickSuccessHandler={onCLickSuccessHandler} />
      ) : (
        <ChangePassword />
      )}
      <img src={bg1} alt="" className="bg2" />
    </div>
  );
};

export default ForgetPasswordScreen;
