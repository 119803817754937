import React, { useState } from 'react'
import AddToCartComp from '../Components/AddToCart/AddToCartComp'
import AddToCartHead from '../Components/AddToCart/AddToCartHead'
import AddCartBotttomComp from '../Components/AddToCart/AddCartBotttomComp'

const AddToCartScreen = () => {
    const [count, setCount] = useState(1)
    const addClick = () => {
        setCount(count + 1)

    }
    const lessClick = () => {
        setCount(count - 1)
        if (count <= 1) {
            setCount(1)
        }
    }

    return (
        <div className="h-100 w-100">
            <div className=''>
                <AddToCartHead />
            </div>
            <div className='w-100 d-flex ac-jc mb-2' >
                <AddToCartComp addClick={addClick} lessClick={lessClick} count={count} />
            </div>
            <div className='w-100 d-flex ac-jc mb-5' >
                <AddCartBotttomComp count={count} />
            </div>
        </div>
    )
}

export default AddToCartScreen