import React from 'react'
import LeftAuth from '../Components/RegisterAndLoginComp/LeftAuth'
import RegistrationAuth from '../Components/RegisterAndLoginComp/RegistrationAuth'
import { bg1 } from '../assets/img'

const SignUpScreen = () => {
    return (
        <div className="d-flex flex-column flex-md-row sign-up position-relative align-items-center justify-content-center">
            <h5
                // onClick={() => navigate("/")}
                className="sect-title mb-0 primary log-logo">
                Ninos
            </h5>
            <img src={bg1} alt="" className="bg1" />
            <LeftAuth />
            <RegistrationAuth />
            <img src={bg1} alt="" className="bg2" />
        </div>
    )
}

export default SignUpScreen