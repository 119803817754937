import { DateRangeOutlined, Delete, DeleteOutline } from '@mui/icons-material'
import React from 'react'
import { product } from '../../assets/img'

const AddToCartComp = ({ lessClick, addClick, count }) => {
    return (
        <div className='overflow-y-scroll mt-5' style={{ overflow: 'scroll' }}>
            <table className="rwd-table float-start mt-3 d-flex ac-jc">
                <tbody>
                    <tr className='d-flex align-items-center'>
                        <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-center' style={{ width: '500px' }}>PRODUCT</th>
                        <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-center' style={{ width: '500px' }}>QUANTITY</th>
                        <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-end ' style={{ width: '300px' }}>TOTAL</th>
                    </tr>
                    <div className='borer-r rounded-4 mt-3 mx-5'>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50 w-sm-60   w-100'>
                                        <div className='d-flex gap-3'>
                                            <div style={{ width: '150px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>                                            <div>
                                                <p className="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Softstyle Adult Pique Polo White</p>
                                                <p className="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Rs. 889.00</p>
                                                <p className="f4 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Size: S</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3 justify-content-center' style={{ width: '500px' }}>
                                <div className='py-3 px-4 ac-jb gap-5 d-flex borer-r rounded-5'>
                                    <button className='my-0 f2 fs-21 fs-sm-22 fs-xs-23 fs-md-24 fs-lg-25 primary mt-0 mb-0 border-0 bg-transparent' onClick={lessClick}>-</button>
                                    <p className='my-0 f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0'>{count}</p>
                                    <button className='my-0 f2 fs-21 fs-sm-22 fs-xs-23 fs-md-24 fs-lg-25 primary mt-0 mb-0 border-0 bg-transparent' onClick={addClick}>+</button>
                                </div>
                                <button className='py-3 ac-jb gap-5 d-flex bg-transparent border-0'>
                                    <DeleteOutline />
                                </button>
                            </td>
                            <td className='d-flex align-items-center justify-content-end px-4' style={{ width: '300px' }}>
                                <p className="f3 fs-13 fs-sm-14 fs-xs-15 fs-md-16 fs-lg-17 primary mt-0 mb-0">Rs.{count * 889.00}.00
                                </p>
                            </td>
                        </tr>
                    </div>
                    <div className='borer-r rounded-4 mt-3 mx-5'>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50 w-sm-60   w-100'>
                                        <div className='d-flex gap-3'>
                                            <div style={{ width: '150px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>                                            <div>
                                                <p className="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Softstyle Adult Pique Polo White</p>
                                                <p className="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Rs. 889.00</p>
                                                <p className="f4 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Size: S</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3 justify-content-center' style={{ width: '500px' }}>
                                <div className='py-3 px-4 ac-jb gap-5 d-flex borer-r rounded-5'>
                                    <button className='my-0 f2 fs-21 fs-sm-22 fs-xs-23 fs-md-24 fs-lg-25 primary mt-0 mb-0 border-0 bg-transparent' onClick={lessClick}>-</button>                                    <p className='my-0 f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0'>{count}</p>
                                    <button className='my-0 f2 fs-21 fs-sm-22 fs-xs-23 fs-md-24 fs-lg-25 primary mt-0 mb-0 border-0 bg-transparent' onClick={addClick}>+</button>
                                </div>
                                <button className='py-3 ac-jb gap-5 d-flex bg-transparent border-0'>
                                    <DeleteOutline />
                                </button>
                            </td>
                            <td className='d-flex align-items-center justify-content-end px-4' style={{ width: '300px' }}>
                                <p className="f3 fs-13 fs-sm-14 fs-xs-15 fs-md-16 fs-lg-17 primary mt-0 mb-0">Rs.{count * 889.00}.00
                                </p>
                            </td>
                        </tr>
                    </div>
                    <div className='borer-r rounded-4 mt-3 mx-5'>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50 w-sm-60   w-100'>
                                        <div className='d-flex gap-3'>
                                            <div style={{ width: '150px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>                                            <div>
                                                <p className="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Softstyle Adult Pique Polo White</p>
                                                <p className="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Rs. 889.00</p>
                                                <p className="f4 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Size: S</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3 justify-content-center' style={{ width: '500px' }}>
                                <div className='py-3 px-4 ac-jb gap-5 d-flex borer-r rounded-5'>
                                    <button className='my-0 f2 fs-21 fs-sm-22 fs-xs-23 fs-md-24 fs-lg-25 primary mt-0 mb-0 border-0 bg-transparent' onClick={lessClick}>-</button>                                    <p className='my-0 f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0'>{count}</p>
                                    <button className='my-0 f2 fs-21 fs-sm-22 fs-xs-23 fs-md-24 fs-lg-25 primary mt-0 mb-0 border-0 bg-transparent' onClick={addClick}>+</button>
                                </div>
                                <button className='py-3 ac-jb gap-5 d-flex bg-transparent border-0'>
                                    <DeleteOutline />
                                </button>
                            </td>
                            <td className='d-flex align-items-center justify-content-end px-4' style={{ width: '300px' }}>
                                <p className="f3 fs-13 fs-sm-14 fs-xs-15 fs-md-16 fs-lg-17 primary mt-0 mb-0">Rs.{count * 889.00}.00
                                </p>
                            </td>
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>
    )
}

export default AddToCartComp