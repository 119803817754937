import React from "react";
import { classifiedJson, templateColor } from "../../redux/api/DummyJson";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
const BannerBottomAbout = () => {
  return (
    <div className="d-flex fc fr-md fd-rr w-90 w-md-70 m-auto ac my-3 flex-md-row-reverse">
      <div className="w-50 w-md-30">
        <img
          className="w-70 w-md-90 w-lg-70 "
          src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
          alt=""
        />
      </div>
      <div className="w-100 w-md-70">
        <h3
          className="fs-16 fs-sm-18 fs-md-20 fs-lg-22 f3 text-dark"
        // style={{ color: templateColor.primary }}
        >
          About Us
        </h3>
        <h5
          className='parag disc fs-13 fs-sm-14 fs-md-15 fs-lg-16 f2'
        // "fs-13 fs-sm-14 fs-md-15 fs-lg-16 f2"
        // style={{ color: templateColor.secondary }}
        >
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
          nobis quis odio totam voluptatum impedit aliquid doloremque
          consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
          incidunt exercitationem numquam!
        </h5>
        <p
          className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f1"
        // style={{ color: templateColor.black }}
        >
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
          nobis quis odio totam voluptatum impedit aliquid doloremque
          consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
          incidunt exercitationem numquam!
        </p>
        <p
          className="text-dark fs-12 fs-sm-13 fs-md-14 fs-lg-16 f2 ms-0 px-0 mb-2 d-flex"
        // style={{ color: templateColor.black }}
        >
          Price: $
          <span className="ps-0">
            98
            <strike className="err ps-1  fs-12 fs-lg-13">100</strike>
          </span>
        </p>
        <p
          className="fs-12 fs-md-13 fs-lg-15 f3 ms-0 px-0 mb-2 d-flex primary"
        // style={{ color: templateColor.secondary }}
        >
          Qty: No.200
        </p>
        {classifiedJson?.map((it, ind) => {
          return (
            <div className="d-flex">
              <ControlCameraIcon
                className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0 primary"
              // style={{ color: templateColor.primary }}
              />
              <li className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f2 ms-0 px-0 mb-2 d-flex">
                {it?.discription}
              </li>
            </div>
          );
        })}
        <button
          className="get-start-btn border-0 my-0 mx-2"
        // style={{
        //   background: templateColor.secondary,
        //   color: templateColor.white,
        // }}
        >
          Call to action
        </button>
      </div>
    </div>
  );
};

export default BannerBottomAbout;
