import React from 'react'
import { letsWorkIcon } from '../../assets/img'
import { useNavigate } from 'react-router-dom'

const CollectionPage5 = () => {
    const navigate = useNavigate()
    return (
        <div className="w-xs-90 w-sm-90 w-md-90 w-xxl-75 mx-auto mt-4 mt-lg-4">
            <h4 className="sect-title text-center">Collection Name</h4>
            {/* <p className="parag text-center">Select one of the options below:</p> */}
            <div className="d-flex flex-wrap flex-column flex-sm-row ac-jc m-auto">
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-33">
                    <div className=" let-start-box">
                        <div className="mt-2 mt-md-4 mt-xl-5" onClick={() => { navigate('/collection-page-5/product-page-purchase') }} style={{ cursor: 'pointer' }}>
                            <img src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" className="work1" alt="" />
                        </div>

                        <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 primary">
                            Reference Image
                        </h6>
                        <button
                            onClick={() => navigate('/collection-page-5/add-to-cart')}
                            className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar">
                            Add to Cart
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionPage5