import React from 'react'
import { serv1 } from '../../assets/img'

const CollectionPage1 = () => {
    return (
        <div className="our-services mt-4 mt-md-5">
            <h4 className="sect-title text-center mb-4 mb-sm-5">
                Collection Name
            </h4>
            <div className="box-merch d-flex flex-wrap">
                <div className="rit-send-merch px-1">
                    <div className="content-box align-items-center  text-center text-md-start ps-md-4">
                        <img
                            className="w-50 w-md-90 w-lg-70 mx-auto d-flex"
                            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
                            alt=""
                        />
                        <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3">Reference Image</h3>
                    </div>
                </div>
                <div className="rit-send-merch px-1">
                    <div className="content-box align-items-center  text-center text-md-start ps-md-4">
                        <img
                            className="w-50 w-md-90 w-lg-70 mx-auto d-flex"
                            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
                            alt=""
                        />
                        <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3">Reference Image</h3>
                    </div>
                </div>
                <div className="rit-send-merch px-1">
                    <div className="content-box align-items-center  text-center text-md-start ps-md-4">
                        <img
                            className="w-50 w-md-90 w-lg-70 mx-auto d-flex"
                            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
                            alt=""
                        />
                        <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3">Reference Image</h3>
                    </div>
                </div>
                <div className="rit-send-merch px-1">
                    <div className="content-box align-items-center  text-center text-md-start ps-md-4">
                        <img
                            className="w-50 w-md-90 w-lg-70 mx-auto d-flex"
                            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
                            alt=""
                        />
                        <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3">Reference Image</h3>
                    </div>
                </div>
                <div className="rit-send-merch px-1">
                    <div className="content-box align-items-center  text-center text-md-start ps-md-4">
                        <img
                            className="w-50 w-md-90 w-lg-70 mx-auto d-flex"
                            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
                            alt=""
                        />
                        <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3">Reference Image</h3>
                    </div>
                </div>
                <div className="rit-send-merch px-1">
                    <div className="content-box align-items-center  text-center text-md-start ps-md-4">
                        <img
                            className="w-50 w-md-90 w-lg-70 mx-auto d-flex"
                            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
                            alt=""
                        />
                        <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3">Reference Image</h3>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CollectionPage1


