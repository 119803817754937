import React from 'react'
import OrdeHistoryRight from '../Components/OrdeHistoryCom/OrdeHistoryRight'
import OrdeHistoryCom from '../Components/OrdeHistoryCom/OrdeHistoryCom'
import { Col, Row } from 'react-bootstrap'

const OrderHistory = () => {
    return (
        <div className="h-100 w-100 px-5 mt-5">
            <h5 class="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 text-dark res-wrap2">Order History</h5>
            <Col className='w-100 d-flex res-wrap' >
                <Row className='w-100'>
                    <OrdeHistoryCom />
                </Row>
                <Row className='w-100'>
                    <OrdeHistoryRight />
                </Row>
            </Col>
        </div>
    )
}

export default OrderHistory