import React, { useState } from 'react'
import { closeIcon } from '../../assets/img'

const CodeVerifyPopup = ({ PopupShowHandler }) => {

    return (
        <div className="modal-popup d-flex ac-jc">
            <div className="back-close" />
            <div className='center-content3 px-4 py-4 d-flex flex-column ac-jc'>
                <div className=" d-flex ac-jb">
                    <button
                        className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
                        onClick={PopupShowHandler}
                    >
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <h3 className="f4 fs-25 primary mb-0 text-center">Code Verified Successfuly</h3>
                <div className='w-100 d-flex ac-jc mt-5'>
                    <button
                        onClick={PopupShowHandler}
                        className="get-start-btn w-20 border-0 my-0 mx-2">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CodeVerifyPopup