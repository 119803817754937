import React, { useState } from 'react'
import Header from '../navbar/Header'
import { Outlet } from 'react-router-dom'
import Footer1 from '../navbar/Footer copy'
import Footer from '../navbar/Footer'

const Layout = () => {
    const [footer, setFooter] = useState(0)
    return (
        <div className="flex-wrap">
            <Header setFooter={setFooter} />
            <div className="out-left col">
                <Outlet />
            </div>
            <div className="d-block w-100 h-100">
                {/* <div className="d-block w-100 h-100 mt-5 pt-3"> */}
                <Footer footer={footer} />
                {/* <Footer1 footer={footer} /> */}
            </div>
        </div>
    )
}

export default Layout