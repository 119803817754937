import React from 'react'
import { useNavigate } from 'react-router-dom'

const AddCartBotttomComp = ({ count }) => {
    const navigate = useNavigate()
    return (
        <div className='w-82 h-100'>
            <div className='w-100'>
                <div className='d-flex gap-3 w-100 ac-jc justify-content-md-end mt-3'>
                    <p className="f3 fs-13 fs-sm-14 fs-xs-15 fs-md-16 fs-lg-17 primary mt-0 mb-0">Subtotal</p>
                    <p className="f3 fs-13 fs-sm-14 fs-xs-15 fs-md-16 fs-lg-17 primary mt-0 mb-0">Rs. {count * 889.00}.00</p>
                </div>
                <div className='d-flex gap-3 w-100 ac-jc justify-content-md-end mt-3'>
                    <p className="f1 fs-11 fs-sm-12 fs-xs-13 fs-md-14 fs-lg-15 primary1 mt-0 mb-0">Taxes and shipping calculated at checkout</p>
                </div>
                <div className='d-flex gap-3 w-100 ac-jc justify-content-md-end mt-3'>
                    <button className="get-start-btn border-0 my-0 mx-2 w-md-30" onClick={() => navigate('/collection-page-5/add-to-cart/redeem')}>Buy Now</button>
                </div>
            </div>
        </div>
    )
}

export default AddCartBotttomComp