// @ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveLoginHeader } from "../../redux/slice/loginHeader";

const LoginAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginHandler = () => {
    navigate("/dashboard");
    dispatch(saveLoginHeader("login"));
  };
  return (
    <div className="rit-auth reg-auth-rt">
      <div className="form-box">
        <h5>Login</h5>
        <div className="box">
          <p>Email</p>
          <input className="" />
        </div>
        <div className="box">
          <p>Password</p>
          <input className="" />
        </div>
        <button
          onClick={() => navigate("/forgot-password")}
          style={{ padding: "0 10px", fontFamily: "f1", fontSize: "14px" }}
          className="text-danger ms-auto me-0 d-flex mb-2 cust-btn">
          Forgot Password
        </button>
        <div className="box">
          <button
            onClick={() => navigate('/ninos')}
            className="w-100 mx-auto d-flex ac-jc border-0 login-btn bg-primar mb-3 rounded rounded-2 text-white">
            Login
          </button>
        </div>

        {/* <button
          onClick={() => navigate("/forgot-password")}
          className="text-danger ms-auto me-0 d-flex mb-2 cust-btn">
          Forgot Password
        </button> */}
        <p className="parag f2 text-center d-flex flex-wrap ac-jc">
          Already have an account?{"  "}
          <button
            onClick={() => navigate("/signup")}
            className="f3 primary ms-1 cust-btn">
            Signup
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginAuth;
