import React from 'react'
import { useNavigate } from 'react-router-dom'

const PasswordComp = () => {
    const navigate = useNavigate()
    return (
        <div className="rit-auth reg-auth-rt">
            <div className="form-box">
                {/* <h5>Login</h5> */}
                <div className="box">
                    <p>Enter Password to access Content</p>
                    <input className="" placeholder='[PASSWORD]' />
                </div>
                <button
                    // onClick={() => navigate("/forgot-password")}
                    style={{ padding: "0 10px", fontFamily: "f1", fontSize: "14px" }}
                    className="text-danger ms-auto me-0 d-flex mb-2 cust-btn">

                </button>

                <div className="box">
                    <button className="w-100 mx-auto d-flex ac-jc border-0 login-btn bg-primar mb-3 rounded rounded-2 text-white"
                        onClick={() => navigate('/password/collection-page-1')}
                    >Submit</button></div>
            </div>
        </div>
    )
}

export default PasswordComp