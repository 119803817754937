import React from 'react'
import PasswordComp from '../Components/PasswordComps/PasswordComp'

const PasswordScreen = () => {
    return (
        <div className="d-flex flex-column flex-md-row sign-up align-items-center justify-content-center" style={{ zIndex: -3 }}>
            <PasswordComp />
        </div>
    )
}

export default PasswordScreen