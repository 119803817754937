// @ts-nocheck
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { emailIcon } from "../../assets/img";

const ChangePassword = () => {
  const navigate = useNavigate();
  return (
    <div className="rit-auth forgot-auth-rt">
      <div className="form-box">
        <h5>Email Sent</h5>
        {/* <div className="box">
          <p>Email</p>
          <input className="" />
        </div> */}
        <img src={emailIcon} className="for-email-icon" alt="" />
        <p className="f2 w-95 primary1 fs-14 fs-lg-15 fs-xl-17 text-center">
          Email has been sent to example@gmail.com with instructuins or
          resetting your password
        </p>
        <button
          onClick={() => navigate("/login")}
          className="text-white get-start-btn w-100 d-flex mb-2  mt-4 border-0"
        >
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
