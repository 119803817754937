import React from "react";
import { Link, useNavigate } from "react-router-dom";

const RegistrationAuth = () => {
  const navigate = useNavigate()
  return (
    <div className="rit-auth reg-auth-rt">
      <div className="form-box form-box1">
        <h3 class="fs-23 fs-sm-24 fs-md-25 fs-lg-26 f3 text-dark">Personal Information</h3>
        <div className="box">
          <p>First Name</p>
          <input className="" />
        </div>
        <div className="box">
          <p>Last Name</p>
          <input className="" />
        </div>
        <h3 class="fs-23 fs-sm-24 fs-md-25 fs-lg-26 f3 text-dark">Sign-in Information</h3>
        <div className="box">
          <p>Email</p>
          <input className="" />
        </div>
        <div className="box">
          <p>Password</p>
          <input className="" />
        </div>
        <div className="box">
          <p>Confirm Password</p>
          <input className="" />
        </div>

        <div className="box">
          <button onClick={() => navigate('/login')} className="w-100 mx-auto d-flex ac-jc border-0 login-btn bg-primar rounded rounded-2 text-white mb-2">
            Register
          </button>
        </div>
        <p className="parag f2 text-center d-flex flex-wrap ac-jc">
          Already have an account?{"  "}
          <Link to="/login" className="f3 primary ms-1">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegistrationAuth;
