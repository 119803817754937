// @ts-nocheck
import React from "react";
import { login } from "../../assets/img";

const LeftAuth = () => {
  return (
    <div className="left-auth">
      <div className="">
        <h4 className="sect-title mb-1 text-dark fs-1">Welcome!</h4>
        <p className="parag">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Omnis quo
          <br className="d-none d-lg-block" />
          eveniet molestiae possimus, voluptatum neque eaque
        </p>
        <img src={login} alt="" />
      </div>
    </div>
  );
};

export default LeftAuth;
