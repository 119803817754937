import React from 'react'

const CollectionBanner = () => {
    return (
        <div className="w-100">
            <img
                className="w-100"
                src="https://img.freepik.com/free-vector/professional-website-banner-with-orange-shapes_1361-1755.jpg?w=1380&t=st=1695030403~exp=1695031003~hmac=eccaa86a1692f3348aad68963c082ff5d04942807d8bdd979b76f9566aad56b8"
                alt=""
            />
        </div>
    )
}

export default CollectionBanner