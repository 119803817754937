import React, { useState } from 'react'
import DoneIcon from "@mui/icons-material/Done";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

const ShippingAddComp = () => {
    const [select, setSelect] = useState(false)
    return (
        <div className='w-100'>
            <p className="f2 fs-xl-15 primary1 fs-lg-13 fs-md-16 fs-sm-15 fs-xs-12 mt-2">
                Shipping address
            </p>
            <div className='box5 w-100 d-flex ac-jb mt-4 form-box'>
                <div className="box w-100 custom-palce">
                    <select placeholder='Country/Region' className="cust-btn f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 mb-0">
                        <option value="all" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>State</option>
                        <option value="Pack" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Pack</option>
                        <option value="Products" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Products</option>
                    </select>
                </div>
            </div>
            <div className='w-100 d-flex flex-column flex-md-row gap-3 mt-4 ac-jb mt-2 form-box'>
                <div className="box5 w-100 custom-palce">
                    <input placeholder="First Name (Optional)" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                </div>
                <div className="box5 custom-palce w-100">
                    <input placeholder="Last Name" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                </div>
            </div>
            <div className='w-100 d-flex ac-jb mt-4 form-box'>
                <div className="box5 w-100 custom-palce">
                    <input placeholder="Company (Optional)" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                </div>
            </div>
            <div className='box-custom w-100 d-flex ac-jb mt-4 form-box'>
                <input placeholder="Address" className="w-100 px-3 f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 bg-transparent border-0" />
                <SearchOutlinedIcon className='primary1 me-3' />
            </div>

            <div className='w-100 d-flex ac-jb mt-4 form-box'>
                <div className="box5 custom-palce w-100">
                    <input placeholder="Apartment, suite, etc. (Optional)" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                </div>
            </div>
            <div className='w-100  mt-4 ac-jb mt-2 form-box'>
                <div className="box5 box custom-palce w-100 d-flex gap-3 flex-column flex-md-row">
                    <input placeholder="City" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    <select placeholder='State' className="cust-btn f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 mb-0">
                        <option value="all" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>State</option>
                        <option value="Pack" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Pack</option>
                        <option value="Products" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Products</option>
                    </select>
                    <input placeholder="PIN Code" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                </div>
            </div>
            <div className='box-custom w-100 d-flex ac-jb mt-4 form-box'>
                <input placeholder="Phone" className="w-100 px-3 f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 bg-transparent border-0" />
                <HelpOutlineRoundedIcon className='primary1 me-3' />
            </div>
            <div className="d-flex selectCheckBox mt-3">
                <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                        setSelect(!select);
                    }}
                >
                    <DoneIcon
                        className={`${select ? "tickBoxDesign1" : "tickBoxDesign4"
                            } rounded-1`}
                    />
                </button>
                <p className="f3 primary ms-1 cust-btn fs-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9 mt-2 mb-0 ms-2" onClick={() => {
                    setSelect(!select);
                }}>Save this information for next time</p>
            </div>
            <button className="get-start-btn border-0 mt-0 float-end mt-5">Continue</button>
        </div>
    )
}

export default ShippingAddComp