import React, { useEffect, useState } from "react";
import { submenu, templateColor, tempMenu } from "../../redux/api/DummyJson";
import Search from "./Search";
import { useNavigate } from "react-router-dom";
import { bulkPack, userIcon } from "../../assets/img";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Dropdown } from "react-bootstrap";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const Headercomp = ({ setFooter }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0)
  const [more, setMore] = useState(false)
  const [subdropdown, setSubDropDown] = useState(false)
  const [subdropdown2, setSubDropDown2] = useState(false)

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = event => {
      setScrollTop(window.scrollY);
    };
    if (scrollTop > 0) {
      setShowDropList(false)
      setShowDropList2(false)
      setshowSigndropList(false)
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollTop]);

  const moreClick = () => {
    setMore(!more)
  }
  const [showdropList, setShowDropList] = useState(false)
  const [showdropList2, setShowDropList2] = useState(false)
  const [showSigndropList, setshowSigndropList] = useState(false)

  return (
    <div className={`${"top-header d-flex flex-row ac jsb w-100  px-3  pt-5 pb-2 shadow p-3 bg-body rounded"}`}>
      {/* <div className="d-flex flex-row ac jsb w-100 mb-2 px-3  pt-5"> */}
      <Search />
      <h3 className="f4 fs-25 primary mb-0">NINOS</h3>
      {/* <h3 className="f4 fs-25 primary mb-0">{scrollTop}</h3> */}
      <div className="d-md-flex d-none ac-je gap-3 flex-wrap w-70">
        {tempMenu?.map((it, ind) => {
          if (ind <= 4 && more === false) {
            return (
              <div className="position-relative">
                <button
                  className="fs-15 f3 mb-0 cust-btn px-3 text-dark menu-head-btn menu-list"
                  style={{ color: templateColor.primary }}
                  onClick={() => { navigate(it.navi); setFooter(ind); setActive(ind); it?.menu === 'Collection Page-1' ? setShowDropList2(!showdropList2) : setShowDropList2(false); }}
                >
                  {it?.menu}
                </button>
                {active == ind && <div className="active d-none d-md-flex" />}
                {it?.menu === 'Collection Page-1' &&
                  <div className="menu-item ">
                    {showdropList2 && < div className="invisible-cont" onClick={() => setShowDropList2(false)} />}
                    <div className={`${showdropList2 ? "d-block" : "d-none"} drop-list py-4`}>
                      <button
                        className="border-0 bg-transparent d-flex align-items-center dorp-rit-cont w-100 px-3"
                      >
                        <h5 className="menu1 my-0 py-1 fs-15">Submenu1</h5>
                      </button>
                      <button
                        className="border-0 bg-transparent d-flex align-items-center dorp-rit-cont w-100 px-3 "
                      >
                        <h5 className="menu1 my-0 py-1 fs-15">Submenu2</h5>
                      </button>
                      <button
                        className="border-0 bg-transparent px-0 position-relative dorp-rit-cont w-100 "
                      >
                        <div className="d-flex ac-jb px-3" onClick={() => { setSubDropDown2(!subdropdown2); setSubDropDown(false) }}>
                          <h5 className={`${subdropdown2 && 'text-decoration-underline'} fs-15 my-0 py-1`}>Submenu3</h5>
                          {!subdropdown2 && <KeyboardArrowDownIcon />}
                          {subdropdown2 && <KeyboardArrowUpIcon />}
                        </div>
                        {subdropdown2 &&
                          <div className="mt-2 w-100" style={{
                            background: 'rgb(222 219 219 / 32%)'
                          }}>
                            <button
                              className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                            >
                              <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                            </button>
                            <button
                              className="border-0 bg-transparent dorp-rit-cont  py-2 w-100 "
                            >
                              <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                            </button>
                            <button
                              className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                            >
                              <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                            </button>
                            <button
                              className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                            >
                              <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                            </button>
                          </div>}
                      </button>
                      <button
                        className="border-0 bg-transparent position-relative dorp-rit-cont w-100 px-0"
                      >
                        <div className="d-flex ac-jb px-3" onClick={() => { setSubDropDown(!subdropdown); setSubDropDown2(false) }}>
                          <h5 className={`${subdropdown && 'text-decoration-underline'} my-0 py-1 fs-15`}>Submenu4</h5>
                          {!subdropdown && <KeyboardArrowDownIcon />}
                          {subdropdown && <KeyboardArrowUpIcon />}
                        </div>
                        {subdropdown &&
                          <div className="mt-2 w-100" style={{
                            background: 'rgb(222 219 219 / 32%)'
                          }}>
                            <button
                              className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                            >
                              <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                            </button>
                            <button
                              className="border-0 bg-transparent dorp-rit-cont  py-2 w-100 "
                            >
                              <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                            </button>
                            <button
                              className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                            >
                              <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                            </button>
                            <button
                              className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                            >
                              <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                            </button>
                          </div>}
                      </button>
                    </div>
                  </div>
                }
              </div>
            );
          }
          if (more === true) {
            return (
              <div className="position-relative sub">
                <div className="drop-menu">
                  <button
                    className="fs-16 f3 mb-0 cust-btn px-3 text-dark menu-head-btn menu-list drop-menu2"
                    style={{ color: templateColor.primary }}
                    onClick={() => { navigate(it.navi); setFooter(ind); setActive(ind); it?.menu === 'Collection Page-6' ? setShowDropList(!showdropList) : setShowDropList(false); setActive(ind); it?.menu === 'Collection Page-1' ? setShowDropList2(!showdropList2) : setShowDropList2(false); }}
                  >
                    {it?.menu}
                  </button>
                  {active == ind && <div className="active d-none d-md-flex" />}
                  {it?.menu === 'Collection Page-6' &&
                    <div className="menu-item ">
                      {showdropList && < div className="invisible-cont" onClick={() => setShowDropList(false)} />}
                      <div className={`${showdropList ? "d-block" : "d-none"} drop-list py-4`}>
                        <button
                          className="border-0 bg-transparent d-flex align-items-center dorp-rit-cont w-100 px-3"
                        >
                          <h5 className="menu1 my-0 py-1 fs-15">Submenu1</h5>
                        </button>
                        <button
                          className="border-0 bg-transparent d-flex align-items-center dorp-rit-cont w-100 px-3 "
                        >
                          <h5 className="menu1 my-0 py-1 fs-15">Submenu2</h5>
                        </button>
                        <button
                          className="border-0 bg-transparent px-0 position-relative dorp-rit-cont w-100 "
                        >
                          <div className="d-flex ac-jb px-3" onClick={() => { setSubDropDown2(!subdropdown2); setSubDropDown(false) }}>
                            <h5 className={`${subdropdown2 && 'text-decoration-underline'} fs-15 my-0 py-1`}>Submenu3</h5>
                            {!subdropdown2 && <KeyboardArrowDownIcon />}
                            {subdropdown2 && <KeyboardArrowUpIcon />}
                          </div>
                          {subdropdown2 &&
                            <div className="mt-2 w-100" style={{
                              background: 'rgb(222 219 219 / 32%)'
                            }}>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont  py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                            </div>}
                        </button>
                        <button
                          className="border-0 bg-transparent position-relative dorp-rit-cont w-100 px-0"
                        >
                          <div className="d-flex ac-jb px-3" onClick={() => { setSubDropDown(!subdropdown); setSubDropDown2(false) }}>
                            <h5 className={`${subdropdown && 'text-decoration-underline'} my-0 py-1 fs-15`}>Submenu4</h5>
                            {!subdropdown && <KeyboardArrowDownIcon />}
                            {subdropdown && <KeyboardArrowUpIcon />}
                          </div>
                          {subdropdown &&
                            <div className="mt-2 w-100" style={{
                              background: 'rgb(222 219 219 / 32%)'
                            }}>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont  py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                            </div>}
                        </button>
                      </div>
                    </div>
                  }
                  {it?.menu === 'Collection Page-1' &&
                    <div className="menu-item ">
                      {showdropList2 && < div className="invisible-cont" onClick={() => setShowDropList2(false)} />}
                      <div className={`${showdropList2 ? "d-block" : "d-none"} drop-list py-4`}>
                        <button
                          className="border-0 bg-transparent d-flex align-items-center dorp-rit-cont w-100 px-3"
                        >
                          <h5 className="menu1 my-0 py-1 fs-15">Submenu1</h5>
                        </button>
                        <button
                          className="border-0 bg-transparent d-flex align-items-center dorp-rit-cont w-100 px-3 "
                        >
                          <h5 className="menu1 my-0 py-1 fs-15">Submenu2</h5>
                        </button>
                        <button
                          className="border-0 bg-transparent px-0 position-relative dorp-rit-cont w-100"
                        >
                          <div className="d-flex ac-jb px-3" onClick={() => { setSubDropDown2(!subdropdown2); setSubDropDown(false) }}>
                            <h5 className={`${subdropdown2 && 'text-decoration-underline'} fs-15 my-0 py-1`}>Submenu3</h5>
                            {!subdropdown2 && <KeyboardArrowDownIcon />}
                            {subdropdown2 && <KeyboardArrowUpIcon />}
                          </div>
                          {subdropdown2 &&
                            <div className="mt-2 w-100" style={{
                              background: 'rgb(222 219 219 / 32%)'
                            }}>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont  py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                            </div>}
                        </button>
                        <button
                          className="border-0 bg-transparent position-relative dorp-rit-cont w-100 px-0"
                        >
                          <div className="d-flex ac-jb px-3" onClick={() => { setSubDropDown(!subdropdown); setSubDropDown2(false) }}>
                            <h5 className={`${subdropdown && 'text-decoration-underline'} my-0 py-1 fs-15`}>Submenu4</h5>
                            {!subdropdown && <KeyboardArrowDownIcon />}
                            {subdropdown && <KeyboardArrowUpIcon />}
                          </div>
                          {subdropdown &&
                            <div className="mt-2 w-100" style={{
                              background: 'rgb(222 219 219 / 32%)'
                            }}>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont  py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                              <button
                                className="border-0 bg-transparent dorp-rit-cont py-2 w-100 "
                              >
                                <h5 className="menu1 my-0 fs-15">Subdropdown</h5>
                              </button>
                            </div>}
                        </button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            );
          }
        })}
        {
          !more && <button
            className="fs-15 f5 mb-0 cust-btn px-3 text-dark menu-head-btn menu-list"
            style={{ color: templateColor.primary }}
            onClick={moreClick}
          >
            More
          </button>
        }
        {
          more && <button
            className="fs-15 f5 mb-0 cust-btn px-3 text-dark menu-head-btn menu-list"
            style={{ color: templateColor.primary }}
            onClick={moreClick}
          >
            Less
          </button>
        }
      </div>
      <div className="drop-menu position-relative sign-btn3 z-3" >
        {/* {showSigndropList && <div className="invisible-cont2" onClick={() => setshowSigndropList(false)} />} */}
        <button className="border-0 bg-transparent sign-btn" onClick={() => setshowSigndropList(!showSigndropList)}>
          <img src={userIcon} className="user-icon mx-0" />
        </button>
        <div
          className={`${showSigndropList ? "d-block" : "d-none"
            } sign-drop-list`} style={{ zIndex: 2000 }}>
          <button
            className="border-0 bg-transparent d-flex align-items-center py-2 "
            onClick={() => { navigate('/signup'); setshowSigndropList(false); setActive(undefined) }}
          >
            <h5 className="mb-0">Sign Up</h5>
          </button>
          <button
            className="border-0 bg-transparent d-flex align-items-center py-2 "
            onClick={() => { navigate('/login'); setshowSigndropList(false); setActive(undefined) }}
          >
            <h5 className="mb-0">Login</h5>
          </button>
          {/* <button
            className="border-0 bg-transparent d-flex align-items-center py-2 ">
            <h5 className="mb-0">Help Center</h5>
          </button> */}
          <button
            onClick={() => { navigate('/order-history'); setshowSigndropList(false); setActive(undefined) }}
            className="border-0 bg-transparent d-flex align-items-center py-2 " >
            <h5 className="mb-0">Order History</h5>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Headercomp;
