import React, { useState } from 'react'
import RedeemContactLeft from '../Components/RedeemInfoComp/RedeemContactLeft'
import RedeemContactRight from '../Components/RedeemInfoComp/RedeemContactRight'
import CodeVerifyPopup from '../Components/RedeemInfoComp/CodeVerifyPopup'

const RedeemInfoScreen = () => {
    const [showPop, setShowPop] = useState(false)
    const PopupShowHandler = () => {
        setShowPop(!showPop)
    }
    const [shipPich, setShipPick] = useState(true)
    const changeShip = () => {
        setShipPick(!shipPich)
    }
    return (
        <div className='d-flex flex-column w-100 left-form flex-lg-row right-comp position-relative z-0'
            style={{ overflow: 'scroll' }}
        >
            {showPop && <CodeVerifyPopup PopupShowHandler={PopupShowHandler} />}
            <div className='w-100'>
                <div style={{
                    overflow: 'scroll',
                    scrollBehavior: 'smooth',
                    height: '100%'
                }} className='w-100 pt-3 pb-3'>
                    <h5
                        className="sect-title mb-0 primary log-logo ps-5">
                        NINOS
                    </h5>
                    <RedeemContactLeft setShipPick={setShipPick} />
                </div>
            </div>
            <div className='w-100 custom-bottom-res' style={{
                // overflow: 'scroll',
                // scrollBehavior: 'smooth',
                height: '100%',
            }}>
                <RedeemContactRight shipPich={shipPich} PopupShowHandler={PopupShowHandler} />
            </div>
        </div>
    )
}

export default RedeemInfoScreen