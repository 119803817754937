import { DateRangeOutlined } from '@mui/icons-material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import React from 'react'
import { product } from '../../assets/img'

const OrdeHistoryRight = () => {
    return (
        <div className='overflow-y-scroll w-100 ac-jc my-5 px-5' style={{ overflow: 'scroll' }}>
            <table class="rwd-table float-start mt-3 d-flex ac-jc borer-r pt-4">
                <tbody>
                    <tr className='d-flex'>
                        <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-center' style={{ width: '200px', }}>
                            Order </th>
                        <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11  text-center' style={{ width: '200px', }}>Date</th>
                        <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11   text-center' style={{ width: '200px' }}>Payment Status</th>
                        <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11  text-center' style={{ width: '200px' }}>Full Payment Status</th>
                        <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-center ' style={{ width: '200px' }}>Total</th>
                    </tr>
                    <div className='borer-r mt-3'>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex ac-jc' style={{ width: '200px' }}>
                                <div className='p-4'>
                                    <button class="f4 get-start-btn bg-transparent primary my-0 border-primar">ninos1093</button>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">August 4, 2023
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Voided
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Unfulfilled
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Rs.400.00
                                    </p>
                                </div>
                            </td>
                            {/* <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td> */}
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex ac-jc' style={{ width: '200px' }}>
                                <div className='p-4'>
                                    <button class="f4 get-start-btn bg-transparent primary my-0 border-primar">ninos1093</button>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">August 4, 2023
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Voided
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Unfulfilled
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Rs.400.00
                                    </p>
                                </div>
                            </td>
                            {/* <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td> */}
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex ac-jc' style={{ width: '200px' }}>
                                <div className='p-4'>
                                    <button class="f4 get-start-btn bg-transparent primary my-0 border-primar">ninos1093</button>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">August 4, 2023
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Voided
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Unfulfilled
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Rs.400.00
                                    </p>
                                </div>
                            </td>
                            {/* <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td> */}
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex ac-jc' style={{ width: '200px' }}>
                                <div className='p-4'>
                                    <button class="f4 get-start-btn bg-transparent primary my-0 border-primar">ninos1093</button>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">August 4, 2023
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Voided
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Unfulfilled
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Rs.400.00
                                    </p>
                                </div>
                            </td>
                            {/* <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td> */}
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex ac-jc' style={{ width: '200px' }}>
                                <div className='p-4'>
                                    <button class="f4 get-start-btn bg-transparent primary my-0 border-primar">ninos1093</button>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">August 4, 2023
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Voided
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Unfulfilled
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Rs.400.00
                                    </p>
                                </div>
                            </td>
                            {/* <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td> */}
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex ac-jc' style={{ width: '200px' }}>
                                <div className='p-4'>
                                    <button class="f4 get-start-btn bg-transparent primary my-0 border-primar">ninos1093</button>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">August 4, 2023
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Voided
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Unfulfilled
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0  text-center">Rs.400.00
                                    </p>
                                </div>
                            </td>
                            {/* <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td> */}
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>
    )
}

export default OrdeHistoryRight