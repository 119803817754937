import React, { useState } from 'react'
import DoneIcon from "@mui/icons-material/Done";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

const PickupComp = () => {
    const [select, setSelect] = useState(false)
    return (
        <div className='w-100'>
            <p className="f2 fs-xl-15 primary1 fs-lg-13 fs-md-16 fs-sm-15 fs-xs-12 mt-2">
                Pickup locations
            </p>
            <p className='primary f1'>There is 1 store with stock close to your location</p>
            <div className="estimate-box p-3 p-lg-4 bg-lt-blue mt-3 rounded rounded-4">
                <div className="d-flex ac-jb">
                    <h4 className="f3 primary fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 ">Store Pick Up (279.9 km)</h4>
                    <h4 className="f4 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17 ">Free</h4>
                </div>
                <div className="d-flex ac-jb">
                    <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">#39 Krishna Reddy Colony Domlur, Domlur Layout, Bangalore KA</p>
                    <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">Usually ready in 4 hours</p>
                </div>
            </div>
            <button className="get-start-btn border-0 mt-0 float-end mt-5">Continue</button>
        </div>
    )
}

export default PickupComp