import React, { useState } from 'react'
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { cogwheel, light_mode, night_mode } from '../../assets/img';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StoreIcon from '@mui/icons-material/Store';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ShippingAddComp from './ShippingAddComp';
import PickupComp from './PickupComp';


const RedeemContactLeft = ({ setShipPick }) => {
    const [select, setSelect] = useState(false)

    const [shipping, setShipping] = useState(true)
    const [pickup, setPickUp] = useState(false)


    const shippingClick = () => {
        setShipping(true)
        setPickUp(false)
        setShipPick(true)
    }
    const pickupClick = () => {
        setPickUp(true)
        setShipping(false)
        setShipPick(false)
    }
    return (
        <div className='w-100 d-flex ac-jc' style={{ overflow: 'hidden' }}>

            <div className='d-flex align-custom1 flex-column mt-3 d-flex ac-jc w-90' >
                <div className="box1 w-100">
                    <div className='w-100 d-flex ac-jb'>
                        <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                            Contact
                        </p>
                        {/* <p className="parag f2 text-center d-flex flex-wrap ac-jc fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">Have an account?  <button className="f3 primary ms-1 cust-btn fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">Log in</button></p> */}
                    </div>
                    <input placeholder="Email" type='email' className="f2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    <div className="d-flex selectCheckBox mt-3">
                        <button
                            className="border-0 bg-transparent"
                            onClick={() => {
                                setSelect(!select);
                            }}
                        >
                            <DoneIcon
                                className={`${select ? "tickBoxDesign1" : "tickBoxDesign4"
                                    } rounded-1`}
                            />
                        </button>
                        <p className="f3 primary ms-1 cust-btn fs-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9 mt-2 mb-0 ms-2" onClick={() => {
                            setSelect(!select);
                        }}>Email me with news and offers</p>
                    </div>
                </div>

                <div className="box1 w-100">
                    <p className="f2 primary1 mb-0 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                        Delivery method
                    </p>
                    {/* sellect  */}
                    {/* select 2 */}
                    <div className='form-box-shadow rounded-2 mt-3'>
                        <button onClick={shippingClick} className={shipping ? 'ps-3 w-100 align-items-center  border-0 bg-transparent rounded-5 py-4 select_shadow d-flex  ' : ' ps-3 w-100 align-items-center border-0 bg-transparent rounded-5 py-4 select_shadow d-flex '}>
                            {shipping ? <CheckCircleSharpIcon className='me-4 visible bg-lght rounded-5 text-Blue mb-0 f4 fs-20' /> :
                                <CircleOutlinedIcon className='me-4 visible bg-lght rounded-5 text-Blue mb-0 f4 fs-20' />}
                            <div className='d-flex ac-jc'>
                                <LocalShippingIcon className=' visible rounded-5  mb-0 f4 fs-20' />
                                <div className='ms-4'>
                                    <p className=' text-start mb-0 f3 primary fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Ship</p>
                                </div>
                            </div>
                        </button>
                        <button onClick={pickupClick} className={pickup ? 'ps-3 w-100 align-items-center  border-0 bg-transparent rounded-5 py-4 select_shadow d-flex   ' : ' ps-3 w-100 align-items-center border-0 bg-transparent rounded-5 py-4 select_shadow d-flex '}>
                            {pickup ? <CheckCircleSharpIcon className='me-4 visible bg-lght rounded-5 text-Blue mb-0 f4 fs-20' /> :
                                <CircleOutlinedIcon className='me-4 visible bg-lght rounded-5 text-Blue mb-0 f4 fs-20' />}
                            <div className='d-flex ac-jc'>
                                <StoreIcon className=' visible rounded-5  mb-0 f4 fs-20' />
                                <div className='ms-4'>
                                    <p className=' text-start mb-0 f3 primary fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Pick up</p>
                                </div>
                            </div>
                        </button>
                    </div>

                </div>
                {shipping && <ShippingAddComp />}
                {!shipping && <PickupComp />}
            </div>
        </div>
    )
}

export default RedeemContactLeft