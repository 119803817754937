import React from "react";
import { templateColor, tempMenu } from "../redux/api/DummyJson";
import Headercomp from "../Components/navbar/Header";
import BannerComp from "../Components/template/BannerComp";
import BannerBottom from "../Components/template/BannerBottom";
import BoxListComp from "../Components/template/BoxListComp";
import BannerBottomAbout from "../Components/template/BannerBottomAbout";

const TemplateScreen = () => {
  return (
    <div className="w-100 d-flex flex-column flex-wrap">
      {/* <Headercomp /> */}
      <BannerComp />
      <BannerBottom />
      <BoxListComp />
      <BannerBottomAbout />
    </div>
  );
};

export default TemplateScreen;
