export const BASE_URL = "https://dummyjson.com/";

export const URL = {};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
