import React from 'react'
import CollectionBanner from '../Components/CollectionPages/CollectionBanner'
import CollectionPage1 from '../Components/CollectionPages/CollectionPage1'
import CollectionPage3 from '../Components/CollectionPages/CollectionPage3'

const CollectionScreenThree = () => {
    return (
        <div className="w-100 flex-column flex-wrap">
            <CollectionBanner />
            <CollectionPage3 />
        </div>
    )
}

export default CollectionScreenThree