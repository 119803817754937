import React from 'react'
import { searchIcon } from '../../assets/img'

const AddToCartHead = () => {
    return (
        <div className="my-product mx-auto w-80 pt-5">
            <div className="filter-product d-flex ac-jb ac-jb mt-3 mt-lg-4 px-2 px-lg-3">
                <h4 className="sect-title text-start mb-0 fs-2">Your Cart</h4>
                {/* <div className="position-relative d-flex ac-jc search-icon">
                    <img src={searchIcon} alt="" />
                    <input
                        type="text"
                        className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
                        placeholder="Search Products"
                        name=""
                        id=""
                    />
                </div> */}
            </div>
        </div>
    )
}

export default AddToCartHead