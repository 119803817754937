import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = ({ onCLickSuccessHandler }) => {
  const navigate = useNavigate();
  return (
    <div className="rit-auth forgot-auth-rt">
      <div className="form-box">
        <h5>Reset Password</h5>
        <div className="box">
          <p>Email</p>
          <input className="" />
        </div>
        <p className="f2 w-95 primary1 fs-14 fs-lg-15 fs-xl-17">
          SMS can only be used if a mobile phone number has been configured.
        </p>
        <button
          onClick={onCLickSuccessHandler}
          className="text-white get-start-btn w-100 d-flex mb-3 border-0 mb-0"
        >
          Reset Via SMS
        </button>
        <button
          onClick={onCLickSuccessHandler}
          className="text-white get-start-btn w-100 d-flex mb-2  mt-2 border-0"
        >
          Reset Via Email
        </button>

        <p className="parag f2 text-center d-flex flex-wrap ac-jc">
          Already have an account?{"  "}
          <button
            onClick={() => navigate("/login")}
            className="f3 primary ms-1 cust-btn"
          >
            Sign in
          </button>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
