import React from 'react'
import ProductRedeemPurchaseComp from '../Components/ProductCompPurchase/ProductPurchaseComp'
import ProductBoxPurchase from '../Components/ProductCompPurchase/ProductBoxPurchase'
import DiscriptionCompPurchase from '../Components/ProductCompPurchase/DiscriptionComp'
import DiscriptionCompPurchase2 from '../Components/ProductCompPurchase/DiscriptionComp2'

const ProductPurchase = () => {
    return (
        <div className="w-100 d-flex flex-column flex-wrap">
            <ProductRedeemPurchaseComp />
            <ProductBoxPurchase />
            <DiscriptionCompPurchase />
            <DiscriptionCompPurchase2 />
        </div>
    )
}

export default ProductPurchase