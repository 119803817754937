import React from 'react'
import { product, product1, redeem_animated } from '../../assets/img'
import { useLoaderData, useLocation, useRoutes } from 'react-router-dom'

const RedeemContactRight = ({ PopupShowHandler, shipPich }) => {
    const Path = useLocation()
    console.log()
    return (

        <div className='w-100 ac-jc d-flex'
            style={{
                height: '100vh',
                background: '#E2ECFF',
                overflow: 'hidden',
            }}>
            <div className="w-90 ms-5 h-90 mt-5">
                <div className='d-flex align-items-center gap-3 my-3'>
                    <div className="theem_select rounded-3 p-2 bg-lt-primary border-1 border-dark">
                        <img src={product} className="w-100 object-fit-contain h-100" />
                    </div>
                    <div>
                        <p className="f3 primary cust-btn fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0">Softstyle Midweight Adult T-Shirt White
                        </p>
                        <p className="parag f2 ac-jc fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0">2XL</p>
                    </div>
                </div>
                <div className='d-flex w-80 ac-jc'>
                    <div className="box6 custom-palce">
                        <input placeholder="Enter The Code" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                    <div className="w-25">
                        <button className="get-start-btn border-0 w-100 w-xs-50 w-sm-45 w-lg-70 w-xl-60 w-xxl-55 bg-primar my-0 " onClick={PopupShowHandler}>Apply</button>
                    </div>
                </div>
                {Path?.pathname === '/product-page-redeem/redeem' && <div className='d-flex ac-jb w-80 px-2 mt-3'>
                    <h4 className="f2 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">Shipping</h4>
                    <p className="f3 fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17  primary1 mt-0 mb-0">Free</p>
                </div>}

                {Path?.pathname === '/collection-page-5/product-page-purchase/redeem' &&
                    <div>
                        <div className='d-flex ac-jb w-80 px-2 mt-3'>
                            <h4 className="f2 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">Subtotal
                            </h4>
                            <p className="f3 fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17  primary1 mt-0 mb-0">₹45,0008</p>
                        </div>
                        <div className='d-flex ac-jb w-80 px-2 mt-3'>
                            <h4 className="f2 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">{shipPich ? 'Shipping' : 'Pickup'}</h4>
                            <p className=" f3 fs-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 primary1 mt-0 mb-0">{shipPich ? 'Calculated at next step' : 'Free'}</p>
                        </div>
                        <div className='d-flex ac-jb w-80 px-2 mt-3'>
                            <h4 className="f4 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">Total</h4>
                            <div className='d-flex'>
                                <p className=" f2 fs-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 primary1 mt-0 mb-0 me-1">INR</p>
                                <h4 className="f4 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">₹45,0008</h4>
                            </div>
                        </div>
                    </div>
                }
                {Path?.pathname === '/collection-page-5/add-to-cart/redeem' &&
                    <div>
                        <div className='d-flex ac-jb w-80 px-2 mt-3'>
                            <h4 className="f2 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">Subtotal
                            </h4>
                            <p className="f3 fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17  primary1 mt-0 mb-0">₹45,0008</p>
                        </div>
                        <div className='d-flex ac-jb w-80 px-2 mt-3'>
                            <h4 className="f2 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">{shipPich ? 'Shipping' : 'Pickup'}</h4>
                            <p className=" f3 fs-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 primary1 mt-0 mb-0">{shipPich ? 'Calculated at next step' : 'Free'}</p>
                        </div>
                        <div className='d-flex ac-jb w-80 px-2 mt-3'>
                            <h4 className="f4 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">Total</h4>
                            <div className='d-flex'>
                                <p className=" f2 fs-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 primary1 mt-0 mb-0 me-1">INR</p>
                                <h4 className="f4 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17">₹45,0008</h4>
                            </div>
                        </div>
                    </div>
                }

            </div>

        </div>
    )
}

export default RedeemContactRight