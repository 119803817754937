import React from 'react'
import { searchIcon } from '../../assets/img'

const OurProjecRight = () => {
    return (
        <div className="my-product mx-auto w-100 my-5">
            <div className="filter-product text-start2">
                <h5 className="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 text-dark res-wrap2">
                    Account details
                </h5>
                <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3 res-wrap2">Roger Daniel</h3>
                <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3 res-wrap2">39 39th Cross Road Jayanager 9th Block Jayanagar</h3>
                <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3 res-wrap2">560071 Bengalure KA</h3>
                <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3 res-wrap2">India</h3>
            </div>
            <div className='res-wrap2'>
                <button className='f3 bg-transparent primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3 border-cust align-content-center'>View Adderss</button>
            </div>
        </div>
    )
}

export default OurProjecRight