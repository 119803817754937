export const banner = require("./banner.png");
export const logo2 = require("./logo/duolingo.png");
export const logo3 = require("./logo/notion.png");
export const logo4 = require("./logo/ted.png");
export const logo5 = require("./logo/Vector.png");
export const logo6 = require("./logo/walmart.png");
export const listIcon = require("./list.png");
export const dashboard = require("./dashboard.png");
export const sendMerch = require("./back1.png");
export const clinet1 = require("./client.png");
export const distribBack = require("./back2.png");
export const back3 = require("./back3.png");
export const support = require("./support-img.png");
export const supportPhone = require("./mobile.png");
export const sadEmoj = require("./sad-emoji.png");
export const errorWarn = require("./error-warning.png");
export const userIcon = require("./user.png");
export const profileIcon = require("./profile_icon.png");
export const billingIcon = require("./billing_icon.png");
export const companyIcon = require("./company_icon.png");
export const membershipIcon = require("./membership_icon.png");
export const uploadIcon = require("./upload_icon.png");
export const upload1 = require("./upload1.png");
export const rocketIcon = require("./rocket_icon.png");
export const noteIcon = require("./note_icon.png");
export const downArrow = require("./down-arrow.png");

export const starYellow = require("./star.png");
export const happyEmoj = require("./happy-emoji.png");
export const logist = require("./logistics.png");
export const groupKits = require("./group_of_kits.png");
export const pacImg = require("./small_pack.png");
export const serviceBack = require("./service-back.png");
export const serv1 = require("./serv1.png");
export const img1 = require("./img1.png");
export const bg = require("./bg.png");
export const bg1 = require("./elip1.png");
export const fb = require("./fb.png");
export const google = require("./google.png");
export const twitter = require("./twitter.png");
export const login = require("./login.png");

//product-start
export const build_Pack = require("./build_pack.png");
export const presetPack = require("./preset_pack.png");
export const bulkPack = require("./bulk_pack.png");
export const inovFut = require("./innovate_future.png");
export const closeIcon = require("./close.png");
export const product1 = require("./product_1.png");
export const product2 = require("./product_2.png");
export const delivery_product = require("./delivery_1.png");
export const cloudUpload = require("./bx-cloud-upload.png");
export const shippmentIcon = require("./shippment.png");
//product-start
//company-start
export const ourTeam = require("./our-team.png");
export const missionIcon = require("./our_vision_icon.png");
export const vissionIcon = require("./our_mission_icon.png");
export const teamIcon = require("./team_circle.png");
export const person1 = require("./person.png");
export const ourTeamBack = require("./our-team-back.jpg");
export const joinTeam = require("./join_our_img.png");
export const stayProd = require("./stay_prod.png");
//company-end

//faq-start
export const faq = require("./faq_back.png");
export const visitImg = require("./visit.png");
export const searchIcon = require("./bx-search-alt.png");
export const faqImg = require("./faq_img.png");
//faq-end

//member-ship-start
export const checkIcon = require("./green-check.png");
export const diamondIcon = require("./diamond.png");
export const cancelIcon = require("./red-cancel.png");
export const moneyICon = require("./money.png");
export const work1 = require("./work1.png");
export const work2 = require("./work2.png");
export const work3 = require("./work3.png");
export const internationalShippment = require("./international_shippment.png");
export const samplePack = require("./sample_pack.png");
export const emailIcon = require("./email.png");
export const letsWorkIcon = require("./lets-work.png");
export const SpecialCateg = require("./discount.png");
export const product = require("./product_detail_img.png");
export const whatsNew = require("./whats_new.png");
export const filterAlt = require("./bx-filter-alt.png");
export const ship1 = require("./ship1.png");
export const ship2 = require("./ship2.png");
export const discountIcon = require("./discount_icon.png");
export const commentIcon = require("./comment.png");
export const quickIcon = require("./quick.png");
export const empty_redeem = require("./empty_redeem.webp");
export const bx_filter = require("./bx-filter-alt.png");
export const link_icon = require("./linkicon.png");

//member-ship-end

// Redeem
export const redeem_animated = require("./redeem_animated_img.webp");
export const autoship = require("./autoship.webp");
export const cogwheel = require("./cogwheel.png");
export const file = require("./file.png");
export const light_mode = require("./light_mode.png");
export const night_mode = require("./night_mode.png");
export const redeem_collection = require("./redeem_collection.webp");

// Number Icons

export const numebr_two = require('./iconTwo.png')
export const numebr_three = require('./iconThree.png')


// Blog bg

export const blog_bg = require('./blog_bg.png')

