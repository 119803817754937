import { SearchOffRounded } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const SearchComp = () => {
    // const [scrollTop, setScrollTop] = useState(0);

    const [searchShow, setSearchShow] = useState(false)
    const [colorchange, setColorChange] = useState(false)
    const [searchtop, setSearchTop] = useState(false)

    const changeNavbarColor = () => {
        if (window.scrollY >= 200) {
            setColorChange(true);
            setSearchTop(false)
        }
        else {
            setColorChange(false);
            setSearchTop(true)

        }
    };
    window.addEventListener('scroll', changeNavbarColor);
    return (
        <div className="loginpopuplayout mo-di">
            <div className="modal-content">
                <div className="modal-body gap-2">
                    <div className={colorchange ? 'hide-cont rounded-start-5 bg-dark' : 'show-cont rounded-start-5'} />
                    <button
                        onClick={() => setSearchShow(!searchShow)}

                        className={colorchange ? 'btn ng-opasity-25 w-10 d-flex ac-jc btn-custom bg-transparent' : 'btn ng-opasity-25 w-10 d-flex ac-jc btn-custom'}
                        style={{
                            color: "#fff",
                            height: '60px',
                            width: '60px',
                            borderRadius: '50%',
                        }}
                    >
                        <SearchIcon />
                    </button>
                </div>
                <div>
                    <div className={searchShow && 'loginpopup1'} onClick={() => setSearchShow(!searchShow)} />
                    <div className={searchShow ? 'search-bar w-100  arrow-cont ac-js' : 'w-100 search-bar arrow-cont ac-js form-hide'}>
                        <button className="arrow-box"
                            onClick={() => setSearchShow(false)} >
                            <ArrowBackIcon />
                        </button>
                        <div className='w-100 ms-3 me-3'>
                            <div className='form-cont'>
                                <div className='search-cont d-flex'>
                                    <span className='d-flex'>
                                        <SearchIcon className='ms-3 search-icon' />
                                    </span>
                                    <div className='input-cont d-flex ac-jc'>
                                        <input type="search" placeholder='Search this site' className="fs-13 fs-md-14 fs-lg-17 fs-xl-19 f2 ms-3 input-box p-0 w-90" />
                                    </div>
                                    <button className='border-0 bg-transparent'></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchComp