import React from 'react'
// import { classifiedJson, templateColor } from "../../redux/api/DummyJson";
// import ControlCameraIcon from "@mui/icons-material/ControlCamera";

const DiscriptionComp2 = () => {
    return (
        <div className="d-flex fc fr-md fd-rr w-90 w-md-70 m-auto ac my-3 flex-md-row-reverse">

            <div className="w-50 w-md-30">
                <img
                    className="w-70 w-md-90 w-lg-70 "
                    src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
                    alt=""
                />
            </div>
            <div className="w-100 w-md-70">
                <h4 className="sect-title text-start">Collection Name</h4>
                <h5
                    className='parag disc fs-13 fs-sm-14 fs-md-15 fs-lg-16 f2'
                // "fs-13 fs-sm-14 fs-md-15 fs-lg-16 f2"
                // style={{ color: templateColor.secondary }}
                >
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
                    nobis quis odio totam voluptatum impedit aliquid doloremque
                    consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
                    incidunt exercitationem numquam!
                </h5>
            </div>
        </div>
    )
}

export default DiscriptionComp2