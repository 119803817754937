import React from 'react'
import CollectionBanner from '../Components/CollectionPages/CollectionBanner'
import CollectionPage1 from '../Components/CollectionPages/CollectionPage1'
import CollectionPage3 from '../Components/CollectionPages/CollectionPage3'
import CollectionPage4 from '../Components/CollectionPages/CollectionPage4'
import CollectionPage5 from '../Components/CollectionPages/CollectionPage5'

const CollectionScreenFive = () => {
    return (
        <div className="w-100 flex-column flex-wrap mb-5">
            <CollectionBanner />
            <CollectionPage5 />
        </div>
    )
}

export default CollectionScreenFive