import React from "react";
import { classifiedJson, templateColor } from "../../redux/api/DummyJson";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import { useNavigate } from "react-router-dom";
const BoxListComp = () => {
  const navigate = useNavigate()
  return (
    <div className="d-flex flex-wrap fr w-90 w-md-85 m-auto ac my-3 ">
      <div className="d-flex fc px-1 px-md-3 px-lg-4 w-xs-95 w-md-49 contain home-support w-lg-25 w-xl-32 br-10 py-2 py-lg-4 mx-auto my-2">
        <div className="w-49 w-md-30 mx-auto d-flex">
          <img
            className="w-70 w-md-90 w-lg-70 mx-auto d-flex"
            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
            alt=""
          />
        </div>
        <div className="w-98 mx-auto d-block text-center">
          <h4
            className="fs-14 fs-sm-15 fs-md-16 fs-lg-17 f3 max-line-1 primary px-2"
          // style={{
          //   color: templateColor.primary,
          // }}
          >
            Sit amet consectetur adipisicing eli Sit amet consectetur
            adipisicing eli Sit amet consectetur adipisicing eli Sit amet
            consectetur adipisicing eli consectetur adipisicing eli consectetur
            adipisicing eli consectetur adipisicing eli
          </h4>
          <h5
            className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f1 max-line-2 parag disc px-3"
          // style={{
          //   color: templateColor.secondary,
          // }}
          >
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
            nobis quis odio totam voluptatum impedit aliquid doloremque
            consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
            incidunt exercitationem numquam!
          </h5>
          {/*<p
            className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f1 max-line-2"
            style={{ color: templateColor.black }}
          >
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
            nobis quis odio totam voluptatum impedit aliquid doloremque
            consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
            incidunt exercitationem numquam!
          </p>
          {classifiedJson?.map((it, ind) => {
            return (
              <div className="d-flex text-start">
                <ControlCameraIcon
                  className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0"
                  style={{ color: templateColor.primary }}
                />
                <li className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f2 ms-0 px-0 mb-2 d-flex">
                  {it?.discription}
                </li>
              </div>
            );
          })} */}
          <div className="w-100 ac-jc d-flex mt-4">
            <button
              className="get-start-btn border-0 my-0 mx-2"
              onClick={() => navigate('/product-page-redeem')}
            >
              Add to Redemption
            </button>
          </div>
        </div>
      </div>
      <div className="contain home-support d-flex fc px-1 px-md-3 px-lg-4 w-xs-95 w-md-49  w-lg-25 w-xl-32 br-10 py-2 py-lg-4 mx-auto my-2">
        <div className="w-49 w-md-30 mx-auto d-flex">
          <img
            className="w-70 w-md-90 w-lg-70 mx-auto d-flex"
            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
            alt=""
          />
        </div>
        <div className="w-98 mx-auto d-block text-center">
          <h3
            className="fs-14 fs-sm-15 fs-md-16 fs-lg-17 f3 max-line-1 primary px-2"
          // style={{
          //   color: templateColor.primary,
          // }}
          >
            Sit amet consectetur adipisicing eli Sit amet consectetur
            adipisicing eli Sit amet consectetur adipisicing eli Sit amet
            consectetur adipisicing eli consectetur adipisicing eli consectetur
            adipisicing eli consectetur adipisicing eli
          </h3>
          <h5
            className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f1 max-line-2 parag disc px-3"
          // style={{
          //   color: templateColor.secondary,
          // }}
          >
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
            nobis quis odio totam voluptatum impedit aliquid doloremque
            consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
            incidunt exercitationem numquam!
          </h5>
          {/*<p
            className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f1 max-line-2"
            style={{ color: templateColor.black }}
          >
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
            nobis quis odio totam voluptatum impedit aliquid doloremque
            consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
            incidunt exercitationem numquam!
          </p>
          {classifiedJson?.map((it, ind) => {
            return (
              <div className="d-flex text-start">
                <ControlCameraIcon
                  className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0"
                  style={{ color: templateColor.primary }}
                />
                <li className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f2 ms-0 px-0 mb-2 d-flex">
                  {it?.discription}
                </li>
              </div>
            );
          })} */}
          <div className="w-100 ac-jc d-flex mt-4">
            <button
              className="get-start-btn border-0 my-0 mx-2"
              onClick={() => navigate('/product-page-redeem')}
            >
              Add to Redemption
            </button>
          </div>
        </div>
      </div>
      <div className="contain home-support d-flex fc px-1 px-md-3 px-lg-4 w-xs-95 w-md-49  w-lg-25 w-xl-32 br-10 py-2 py-lg-4 mx-auto my-2">
        <div className="w-49 w-md-30 mx-auto d-flex">
          <img
            className="w-70 w-md-90 w-lg-70 mx-auto d-flex"
            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
            alt=""
          />
        </div>
        <div className="w-98 mx-auto d-block text-center">
          <h3
            className="fs-14 fs-sm-15 fs-md-16 fs-lg-17 f3 max-line-1 primary px-2"
          // style={{
          //   color: templateColor.primary,
          // }}
          >
            Sit amet consectetur adipisicing eli Sit amet consectetur
            adipisicing eli Sit amet consectetur adipisicing eli Sit amet
            consectetur adipisicing eli consectetur adipisicing eli consectetur
            adipisicing eli consectetur adipisicing eli
          </h3>
          <h5
            className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f1 max-line-2 parag disc px-3"
          // style={{
          //   color: templateColor.secondary,
          // }}
          >
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
            nobis quis odio totam voluptatum impedit aliquid doloremque
            consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
            incidunt exercitationem numquam!
          </h5>
          {/*<p
            className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f1 max-line-2"
            style={{ color: templateColor.black }}
          >
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
            nobis quis odio totam voluptatum impedit aliquid doloremque
            consequatur debitis, quas eos id quia illum dolorem, eligendi veniam
            incidunt exercitationem numquam!
          </p>
          {classifiedJson?.map((it, ind) => {
            return (
              <div className="d-flex text-start">
                <ControlCameraIcon
                  className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0"
                  style={{ color: templateColor.primary }}
                />
                <li className="fs-11 fs-sm-12 fs-md-13 fs-lg-14 f2 ms-0 px-0 mb-2 d-flex">
                  {it?.discription}
                </li>
              </div>
            );
          })} */}
          <div className="w-100 ac-jc d-flex mt-4">
            <button
              className="get-start-btn border-0 my-0 mx-2"
              onClick={() => navigate('/product-page-redeem')}
            >
              Add to Redemption
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxListComp;
