import React from 'react'
import { product2 } from '../../assets/img'

const CollectionPage3 = () => {
    return (
        <div className="our-services mt-4 mt-md-5 mb-5">
            <h4 className="sect-title text-center mb-4 mb-sm-5 ms-5">
                Redeem Product
            </h4>
            <div className="box-merch d-flex ac-jc">
                <div className="rit-send-merch px-1">
                    <div className="content-box align-items-center  text-center text-md-start ps-md-4">
                        <img
                            className="w-50 w-md-90 w-lg-70 mx-auto d-flex"
                            alt=""
                            src={product2}
                        />
                        {/* <img
                            className="w-50 w-md-90 w-lg-70 mx-auto d-flex"
                            src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg"
                            alt=""
                        /> */}
                        <p className="text-dark fs-12 fs-sm-13 fs-md-14 fs-lg-16 f2 ms-0 px-0 mb-2 text-center">
                            <strike className="err ps-1  fs-12 fs-lg-13">Rs.1000.00</strike> Rs.<span className="ps-0">100</span></p>
                        <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3">Reference Image</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionPage3