import React from 'react'
import ProductRedeem from '../Components/ProductCompRedeem/ProductRedeem'
import ProductBox from '../Components/ProductCompRedeem/ProductBox'
import DiscriptionComp from '../Components/ProductCompRedeem/DiscriptionComp'
import DiscriptionComp2 from '../Components/ProductCompRedeem/DiscriptionComp2'
// import DiscriptionComp from '../Components/ProductComp/DiscriptionComp'
// import DiscriptionComp2 from '../Components/ProductComp/DiscriptionComp2'

const ProductPageRedeem = () => {
    return (
        <div className="w-100 d-flex flex-column flex-wrap">
            <ProductRedeem />
            {/* <ProductRedeem /> */}
            <ProductBox />
            <DiscriptionComp />
            <DiscriptionComp2 />
            <DiscriptionComp />
            <DiscriptionComp2 />
        </div>
    )
}

export default ProductPageRedeem