import React from 'react'

const ProductBox = () => {
    return (
        <div className="dashboard-screen mx-auto w-90 w-lg-90 w-xl-85 w-xxl-80">
            <div className="w-100 d-flex flex-column flex-sm-row mt-3 flex-row ac-js">
                <button className="cust-btn prod-list-box-img">
                    <img className='object-fit-contain h-100 w-50' src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" alt="" />
                </button>
                <button className="cust-btn prod-list-box-img">
                    <img className='object-fit-contain h-100 w-50' src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" alt="" />
                </button>
                <button className="cust-btn prod-list-box-img">
                    <img className='object-fit-contain h-100 w-50' src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" alt="" />
                </button>
            </div>
        </div>
    )
}

export default ProductBox