import React from 'react'
import CollectionBanner from '../Components/CollectionPages/CollectionBanner'
import CollectionPage2 from '../Components/CollectionPages/CollectionPage2'

const CollectionScreenTwo = () => {
    return (
        <div className="w-100 flex-column flex-wrap">
            <CollectionBanner />
            <CollectionPage2 />
        </div>
    )
}

export default CollectionScreenTwo