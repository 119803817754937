import React from 'react'
import { Route, Routes } from 'react-router-dom'
import TemplateScreen from './Routes/TemplateScreen'

// Style Path
import "../src/assets/sass/default/style.scss";
import "../src/assets/sass/default/responsive.scss";
import "../src/assets/sass/constatnt/custome.scss";
import "../src/assets/sass/constatnt/width.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Layout from './Components/Layout/Layout';
import PasswordScreen from './Routes/PasswordScreen';
import CollectionScreenOne from './Routes/CollectionScreenOne';
import CollectionScreenTwo from './Routes/CollectionScreenTwo';
import CollectionScreenThree from './Routes/CollectionScreenThree';
import CollectionScreenFour from './Routes/CollectionScreenFour';
import CollectionScreenFive from './Routes/CollectionScreenFive';
import ProductPageRedeem from './Routes/ProductPageRedeem';
import ProductPurchase from './Routes/ProductPurchase';
import RedeemInfoScreen from './Routes/RedeemInfoScreen';
import AddToCart from './Routes/AddToCartScreen';
import SignUpScreen from './Routes/SignUpScreen';
import LoginScreen from './Routes/LoginScreen';
import OrderHistory from './Routes/OrderHistory';
import ForgetPasswordScreen from './Routes/ForgetPasswordScreen';


const ReactRouts = () => {
    const routName = "ninos"
    return (
        <Routes>
            <Route path='/forgot-password' element={<ForgetPasswordScreen />} />
            <Route path='/signup' element={<SignUpScreen />} />
            <Route path='/login' element={<LoginScreen />} />
            <Route element={<Layout />}>
                <Route path={routName} index element={<TemplateScreen />} />
                {/* <Route path='/' index element={<TemplateScreen />} /> */}
                <Route path='/password' element={<PasswordScreen />} />
                <Route path='/password/collection-page-1' element={<CollectionScreenOne />} />
                <Route path='/collection-page-2' element={<CollectionScreenTwo />} />
                <Route path='/collection-page-3' element={<CollectionScreenThree />} />
                <Route path='/collection-page-4' element={<CollectionScreenFour />} />
                <Route path='/collection-page-5' element={<CollectionScreenFive />} />
                <Route path='/collection-page-4/product-page-redeem' element={<ProductPageRedeem />} />
                <Route path='/collection-page-5/product-page-purchase' element={<ProductPurchase />} />
                <Route path='/product-page-redeem/redeem' element={<RedeemInfoScreen />} />
                <Route path='/collection-page-5/product-page-purchase/redeem' element={<RedeemInfoScreen />} />
                <Route path='/collection-page-5/product-page-purchase/add-to-cart' element={<AddToCart />} />
                <Route path='/collection-page-5/add-to-cart' element={<AddToCart />} />
                <Route path='/collection-page-5/add-to-cart/redeem' element={<RedeemInfoScreen />} />
                <Route path='/product-page-redeem' element={<ProductPageRedeem />} />

                <Route path='/order-history' element={<OrderHistory />} />
            </Route>
        </Routes>
    )
}

export default ReactRouts