import React from 'react'
import { product1 } from '../../assets/img'
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import { useNavigate } from 'react-router-dom';
import ProductBoxPurchase from './ProductBoxPurchase';


const ProductRedeemPurchaseComp = () => {
    const navigate = useNavigate()
    return (
        <div className="dashboard-screen mx-auto w-90 w-lg-90 w-xl-85 w-xxl-80">
            <div className="d-flex flex-column flex-md-row w-md-100 ac-jb mx-2 mt-3 my-sm-3 my-md-4 flex-row">
                <div className="p-2 p-md-2 my-2 my-lg-3 px-xxl-3 prod-list-login w-60 ">
                    <div className="prod-list-box">
                        <div className="w-100 doneIconDesign">
                            <button className="cust-btn prod-list-box-img">
                                <img className='object-fit-contain h-100 w-50' src="https://visualpharm.com/assets/392/Empty%20Box-595b40b65ba036ed117d1384.svg" alt="" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-30 h-100 res-product">
                    <h4 className="fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 f4 mt-3">Product Name</h4>
                    <p className='primary fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 f2 mt-3'>Description of the Product</p>
                    <div className='d-flex '>
                        <ControlCameraIcon className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0 primary text-nowrap" /> <p>Point 1</p>
                    </div>
                    <div className='d-flex '>
                        <ControlCameraIcon className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0 primary text-nowrap" /> <p>Point 2</p>
                    </div>
                    <div className='d-flex '>
                        <ControlCameraIcon className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0 primary text-nowrap" /> <p>Point 3</p>
                    </div>
                    <div className='d-flex '>
                        <ControlCameraIcon className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0 primary text-nowrap" /> <p>Point 4</p>
                    </div>
                    <div className='d-flex '>
                        <ControlCameraIcon className="fs-12 fs-sm-13 fs-md-14 fs-lg-15 f2 ms-0 px-0 mt-1 me-1 d-flex mb-0 primary text-nowrap" /> <p>Point 5</p>
                    </div>
                    <p className="text-dark fs-12 fs-sm-13 fs-md-14 fs-lg-16 f2 ms-0 px-0 mb-2 d-flex">Price: $<span className="ps-0">98<strike className="err ps-1  fs-12 fs-lg-13">100</strike></span></p>
                    <p className="fs-12 fs-md-13 fs-lg-15 f3 ms-0 px-0 mb-2 d-flex primary">Qty: No.200</p>
                    <button className="f2 get-start-btn bg-lt-blue primary my-0 border-primar w-100 " onClick={() => navigate('/collection-page-5/product-page-purchase/add-to-cart')}>Add to Cart</button>
                    <button className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-100 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar"
                        onClick={() => navigate('/collection-page-5/product-page-purchase/redeem')}
                    > Buy Now</button>
                </div>
            </div>
        </div>
    )
}

export default ProductRedeemPurchaseComp