import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fb, google, logo2, twitter } from "../../assets/img/index";
import { Col } from "react-bootstrap";

const Footer = ({ footer }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    console.log(footer)

    return (
        <div className="footer">
            {footer === 0 &&
                <div className="d-flex p-5 pb-0 mt-0 ac-jc w-100" >
                    <div className="ftr-list w-50 text-center">
                        <h5 className="mb-0">TERMS OF USE
                        </h5>
                        <div className="">
                            <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">This website and all orders are maintained and fulfilled through</Link>
                            <b className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">This website and all orders are maintained and fulfilled through</b>
                            <p className="f4 primary">(CompanyStore.IO)</p>

                        </div>
                    </div>

                </div>}
            {footer === 1 &&
                <div className="d-flex footer-one-sec flex-md-row flex-column">
                    <div className="footer-one-inner-cont">
                        <Col className="w-100">
                            <ul className="class-list-cont ftr-list">
                                <li>
                                    <h5 className="mb-0">POLICIES</h5>
                                </li>
                                <li>
                                    <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">DSR</Link>
                                </li>
                                <li>
                                    <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Return Policy</Link>
                                </li>
                                <li>
                                    <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Customer Support</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col className="w-100">
                            <ul className="class-list-cont ftr-list">
                                <li>
                                    <h5 className="mb-0">POLICIES</h5>
                                </li>
                                <li>
                                    <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">DSR</Link>
                                </li>
                                <li>
                                    <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Return Policy</Link>
                                </li>
                                <li>
                                    <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Customer Support</Link>
                                </li>
                            </ul>
                        </Col>
                    </div>
                </div>
            }
            {footer === 2 && <div className="d-flex flex-column flex-md-row p-5 pb-0 mt-0 as-jb">
                <div className="ftr-list">
                    <h5 className="mb-0">POLICIES</h5>
                    <div className="">
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">DSR</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Privacy Policy</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Return Policy</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Customer Support</Link>
                    </div>
                </div>
                <div className="ftr-list">
                    <h5 className="mb-0">TERMS OF USE
                    </h5>
                    <div className="">
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">This website and all orders are maintained and fulfilled through</Link>
                        <b className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">This website and all orders are maintained and fulfilled through</b>
                        <p className="f4 primary">(CompanyStore.IO)</p>

                    </div>
                </div>
                <div className="ftr-list">
                    <h5 className="mb-0">NOTE ON DELIVERY
                    </h5>
                    <div className="">
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Orders may require longer lead times due to post-pandemic shipping and industry wide supply chain challenges.</Link>
                        {/* <b className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">This website and all orders are maintained and fulfilled through</b> */}
                    </div>
                </div>
            </div>}
            {footer >= 3 && < div className="d-flex flex-column flex-md-row p-5 pb-0 mt-0 as-jb">
                <div className="ftr-list">
                    <h5 className="mb-0">POLICIES</h5>
                    <div className="">
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">DSR</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Privacy Policy</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Return Policy</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Customer Support</Link>
                    </div>
                </div>
                <div className="ftr-list">
                    <h5 className="mb-0">POLICIES</h5>
                    <div className="">
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">DSR</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Privacy Policy</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Return Policy</Link>
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Customer Support</Link>
                    </div>
                </div>
                <div className="ftr-list">
                    <h5 className="mb-0">TERMS OF USE
                    </h5>
                    <div className="">
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">This website and all orders are maintained and fulfilled through</Link>
                        <b className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">This website and all orders are maintained and fulfilled through</b>
                        <p className="f4 primary">(CompanyStore.IO)</p>

                    </div>
                </div>
                <div className="ftr-list">
                    <h5 className="mb-0">NOTE ON DELIVERY
                    </h5>
                    <div className="">
                        <Link className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">Orders may require longer lead times due to post-pandemic shipping and industry wide supply chain challenges.</Link>
                        {/* <b className="primary1 py-1 f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">This website and all orders are maintained and fulfilled through</b> */}
                    </div>
                </div>
            </div>}
            <div className="ftr-btm w-80 mx-auto ">
                {/* <Link className="d-flex parag">
                    <b className="ms-1 text-black">©</b>
                </Link> */}
                <p className="parag text-center d-flex flex-wrap justify-content-center mb-2">
                    &copy;{" "}
                    <b className="ms-1 text-black">{"  "}2023, Powered by
                        developed by</b>
                    <Link className="d-flex parag">
                        <b className="ms-1 primary">Merch HQ</b>
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default Footer